<template>
  <v-container fluid>
    <v-card elevation="2">
      <v-card-text>
        <h1><v-icon class="iconTitle">mdi-cloud-upload</v-icon> Importación de datos</h1><br>
        <v-layout>
          <v-flex xs3>
            <v-select
              label="Tipo de importación"
              v-model="tipo"
              outlined
              @change="cambiaTipo"
              :items="tiposImportacion"
            ></v-select>
          </v-flex>
          <v-flex xs5>
            <v-file-input 
              label="Fichero de datos"
              outlined
              accept=".csv"
              v-model="datos"
              >
            </v-file-input>
          </v-flex>
          <v-flex xs1>
            <v-text-field
              label="Separador"
              outlined
              v-model="separador"
            ></v-text-field>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs2 v-if="reading">
            <center>{{ registrosLeidos }} de {{ registrosTotal }} leídos</center>
            <v-progress-linear
              indeterminate
              color="secondary"
              v-if="registrosLeidos!=registrosTotal && registrosTotal>0"
            ></v-progress-linear>
          </v-flex>
        </v-layout>
        <v-layout align-start justify-center>
          <v-flex xs6 class="elevation-1 pa-3 ma-2">
            <v-list two-line style="cursor:pointer">
              <v-subheader>
                <strong>COLUMNAS DISPONIBLES</strong>
              </v-subheader>
              <draggable v-model="itemsInicial" group='col' style="min-height: 10px">
                <template v-for="item in itemsInicial">
                  <v-list-item :key="item.id">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.title"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </draggable>
            </v-list>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs6 class="elevation-1 pa-3 ma-2">
            <v-list two-line style="cursor:pointer">
              <v-subheader>
                <strong>ORDEN DE COLUMNAS EN FICHERO DE DATOS</strong>
              </v-subheader>
              <draggable v-model="itemsFinal" group='col' style="min-height: 10px">
                <template v-for="item in itemsFinal">
                  <v-list-item :key="item.id">
                    <v-list-item-content>
                      <v-list-item-title v-html="item.title"></v-list-item-title>
                      <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </draggable>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-btn class="btnCustom save" @click="start">Iniciar importación</v-btn>
      </v-card-actions>
    </v-card>
    <v-layout style="margin-top: 20px">
      <v-flex v-if="tipo=='Productores'" xs12>
        <v-data-table
          :headers="headersImportacionProductores"
          :items="datosImportados"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex v-if="tipo=='Fincas'" xs12>
        <v-data-table
          :headers="headersImportacionFincas"
          :items="datosImportados"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex v-if="tipo=='Entradas de producto'" xs12>
        <v-data-table
          :headers="headersImportacionProducto"
          :items="datosImportados"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex v-if="tipo=='Liquidaciones y anticipos'" xs12>
        <v-data-table
          :headers="headersImportacionLiquidaciones"
          :items="datosImportados"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
      <v-flex v-if="tipo=='Rendimientos'" xs12>
        <v-data-table
          :headers="headersImportacionRendimientos"
          :items="datosImportados"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
    <v-layout style="margin-bottom: 3%">
      <v-btn v-if="tipo!=null" class="btnCustom save" @click="acceptData"><v-icon class="btnIcon">mdi-content-save</v-icon> Aceptar datos</v-btn>
      <v-btn v-if="tipo!=null" class="btnCustom delete" @click="clearData"><v-icon class="btnIcon">mdi-delete</v-icon> Limpiar datos</v-btn>
    </v-layout>
    <showDialog ref="dialog" />
    <showMessage ref="snackbar" />
  </v-container>
</template>

<script>
import {BASE_URL} from '../components/constants.js'
import * as basics from '../components/basics.js'
import showMessage from '../components/Messages.vue'
import showDialog from '../components/Dialog.vue'
import genericReload from '../components/genericReload';
import draggable from "vuedraggable";
export default {
  name: 'Importacion',
  data () {
    return {
      tiposImportacion: ['Productores', 'Fincas', 'Entradas de producto', 'Liquidaciones y anticipos', 'Rendimientos'],
      tipo: null,
      datos: null,
      reading: false,
      registrosLeidos: 0,
      registrosTotal: 0,
      separador: ";",
      headersImportacionProductores: [
        {
          text: 'Nombre',
          align: 'start',
          sortable: false,
          value: 'nombre',
        },
        { text: 'Apellidos', value: 'apellidos' },
        { text: 'Código', value: 'codigo' },
        { text: 'CIF', value: 'cif' },
        { text: 'Teléfono', value: 'telefono' },
        { text: 'Email', value: 'email' },
        { text: 'Dirección', value: 'direccion' },
        { text: 'Código postal', value: 'cp' },
        { text: 'Localidad', value: 'localidad' },
        { text: 'Provincia', value: 'provincia' },
        { text: 'Contraseña', value: 'contrasena' },
        { text: '', value: 'actions', sortable: false },
      ],
      headersImportacionFincas: [
        {
          text: 'Identificador',
          align: 'start',
          sortable: false,
          value: 'identificador',
        },
        { text: 'Email', value: 'email' },
        { text: 'Nombre', value: 'nombre' },
        { text: 'Superficie (Ha)', value: 'superficie' },
        { text: 'Plantas', value: 'plantas' },
        { text: 'Variedad', value: 'variedad' },
        { text: 'Polígono', value: 'poligono' },
        { text: 'Parcela', value: 'parcela' },
        { text: 'Recinto', value: 'recinto' },
        { text: 'Localidad', value: 'localidad' },
        { text: 'Provincia', value: 'provincia' },
        { text: '', value: 'actions', sortable: false },
      ],
      headersImportacionProducto: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'fecha',
        },
        { text: 'Productor', value: 'productor' },
        { text: 'Finca', value: 'finca' },
        { text: 'Producto', value: 'producto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Rendimiento', value: 'rendimiento' },
        { text: 'Acidez', value: 'acidez' },
        { text: 'Descuento', value: 'descuento' },
        { text: 'Total', value: 'total' },
        { text: 'Nº Albarán', value: 'nalbaran' },
        { text: 'Comentario', value: 'comentario' },
        { text: '', value: 'actions', sortable: false },
      ],
      headersImportacionLiquidaciones: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'fecha',
        },
        { text: 'Productor', value: 'productor' },
        { text: 'Tipo', value: 'tipo' },
        { text: 'Producto', value: 'producto' },
        { text: 'Calidad', value: 'calidad' },
        { text: 'Campaña', value: 'campana' },
        { text: 'Kilos', value: 'kilos' },
        { text: '€ / Kg', value: 'eurokilo' },
        { text: 'Escandallo', value: 'escandallo' },
        { text: 'Tipo escandallo', value: 'tipoescandallo' },
        { text: 'IVA', value: 'iva' },
        { text: 'IRPF', value: 'irpf' },
        { text: 'Total', value: 'total' },
        { text: '', value: 'actions', sortable: false },
      ],
      headersImportacionRendimientos: [
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value: 'fecha',
        },
        { text: 'Nº Albarán', value: 'albaran' },
        { text: 'Kilos', value: 'kilos' },
        { text: 'Rendimiento', value: 'rendimiento' },
        { text: 'Acidez', value: 'acidez' },
        { text: 'Descuento', value: 'descuento' },
        { text: '', value: 'actions', sortable: false },
      ],
      datosImportados: [],
      itemsInicial: [],
      itemsFinal: []
    }
  },
  components: {
    draggable,
    showMessage,
    showDialog
  },
  mounted(){
  },
  methods:{
    cambiaTipo(){
      switch (this.tipo) {
        case "Productores":
          this.itemsInicial = [
            {
              id: "nombre",
              title: "Nombre",
              subtitle: "Nombre del productor"
            },
            {
              id: "apellidos",
              title: "Apellidos",
              subtitle: "Apellidos del productor"
            },
            {
              id: "codigo",
              title: "Código",
              subtitle: "Código o identificador del productor"
            },
            {
              id: "cif",
              title: "CIF",
              subtitle: "CIF del productor"
            },
            {
              id: "telefono",
              title: "Teléfono",
              subtitle: "Teléfono del productor"
            },
            {
              id: "email",
              title: "Email",
              subtitle: "Email del productor"
            },
            {
              id: "direccion",
              title: "Dirección",
              subtitle: "Dirección del productor"
            },
            {
              id: "cp",
              title: "Código postal",
              subtitle: "Código postal del productor"
            },
            {
              id: "localidad",
              title: "Localidad",
              subtitle: "Localidad del productor"
            },
            {
              id: "provincia",
              title: "Provincia",
              subtitle: "Provincia del productor"
            },
            {
              id: "contrasena",
              title: "Contraseña",
              subtitle: "Contraseña de acceso"
            },
          ];
          this.itemsFinal = [];
          break;
        case "Fincas":
          this.itemsInicial = [
            {
              id: "email",
              title: "Email del productor",
              subtitle: "Email del productor dueño de la finca"
            },
            {
              id: "codigo",
              title: "Código del productor",
              subtitle: "Código del productor dueño de la finca"
            },
            {
              id: "identificador",
              title: "Identificador",
              subtitle: "Identificador sigpac de la finca"
            },
            {
              id: "nombre",
              title: "Nombre",
              subtitle: "Nombre de la finca"
            },
            {
              id: "superficie",
              title: "Superficie (Ha)",
              subtitle: "Superficie en hectáreas de la finca"
            },
            {
              id: "plantas",
              title: "Plantas",
              subtitle: "Plantas de la finca"
            },
            {
              id: "variedad",
              title: "Variedad",
              subtitle: "Variedad de la finca"
            },
            {
              id: "poligono",
              title: "Polígono",
              subtitle: "Polígono de la finca"
            },
            {
              id: "parcela",
              title: "Parcela",
              subtitle: "Parcela de la finca"
            },
            {
              id: "recinto",
              title: "Recinto",
              subtitle: "Recinto de la finca"
            },
            {
              id: "localidad",
              title: "Localidad",
              subtitle: "Localidad de la finca"
            },
            {
              id: "provincia",
              title: "Provincia",
              subtitle: "Provincia de la finca"
            },
          ];
          this.itemsFinal = [];
          break;
        case "Entradas de producto":
          this.itemsInicial = [
            {
              id: "email",
              title: "Email del productor",
              subtitle: "Email del productor al que asociar la entrada"
            },
            {
              id: "codigo",
              title: "Código del productor",
              subtitle: "Código del productor al que asociar la entrada"
            },
            {
              id: "finca",
              title: "Identificador de finca",
              subtitle: "Identificador de la finca"
            },
            {
              id: "producto",
              title: "Producto",
              subtitle: "Producto de la entrada (Aceituna, Cascara, Frutales)"
            },
            {
              id: "fecha",
              title: "Fecha",
              subtitle: "Fecha de la entrada"
            },
            {
              id: "calidad",
              title: "Calidad aceite",
              subtitle: "Calidad en caso de aceite"
            },
            {
              id: "categoría",
              title: "Categoría",
              subtitle: "Categoría del frutal"
            },
            {
              id: "kilos",
              title: "Kilos",
              subtitle: "Kilos de producto"
            },
            {
              id: "arrendado",
              title: "Kilos arrendados",
              subtitle: "Kilos pertenecientes al dueño de la finca en caso de ser arrendada"
            },
            {
              id: "rendimiento",
              title: "Rendimiento",
              subtitle: "Rendimiento del producto"
            },
            {
              id: "acidez",
              title: "Acidez",
              subtitle: "Acidez del producto"
            },
            {
              id: "aceite",
              title: "Aceite",
              subtitle: "Aceite resultante"
            },
            {
              id: "humedad",
              title: "Humedad",
              subtitle: "Humedad en caso de cáscara o frutal"
            },
            {
              id: "pipa",
              title: "Pipa",
              subtitle: "Producto resultante en caso de cáscara o frutal"
            },
            {
              id: "descuento",
              title: "Descuento",
              subtitle: "Descuento de la entrada"
            },
            {
              id: "albaran",
              title: "Nº Albarán",
              subtitle: "Albarán de entrada"
            },
            {
              id: "comentario",
              title: "Comentario",
              subtitle: "Datos adicionales"
            },
          ];
          this.itemsFinal = [];
          break;
        case "Liquidaciones y anticipos":
          this.itemsInicial = [
            {
              id: "email",
              title: "Email del productor",
              subtitle: "Email del productor al que asociar la operación"
            },
            {
              id: "codigo",
              title: "Código del productor",
              subtitle: "Código del productor al que asociar la operación"
            },
            {
              id: "producto",
              title: "Producto",
              subtitle: "Producto a liquidar (Aceituna, Cascara, Frutales)"
            },
            {
              id: "calidad",
              title: "Calidad / categoría",
              subtitle: "Categoría / calidad del producto"
            },
            {
              id: "tipoliquidacion",
              title: "Tipo de liquidación",
              subtitle: "Liquidación por kilos (K) o Anticipo de campaña (A)"
            },
            {
              id: "fecha",
              title: "Fecha",
              subtitle: "Fecha de la operación"
            },
            {
              id: "campana",
              title: "Campaña",
              subtitle: "Campaña de la operación"
            },
            {
              id: "kilos",
              title: "Kilos",
              subtitle: "Kilos liquidados"
            },
            {
              id: "precioKilo",
              title: "€ / Kg",
              subtitle: "Precio del kilo de producto"
            },
            {
              id: "escandallo",
              title: "Escandallo",
              subtitle: "Escandallo de la operación"
            },
            {
              id: "tipoescandallo",
              title: "Tipo de escandallo",
              subtitle: "€ o %"
            },
            {
              id: "iva",
              title: "IVA",
              subtitle: "IVA de la operación"
            },
            {
              id: "irpf",
              title: "IRPF",
              subtitle: "IRPF de la operación"
            },
          ];
          this.itemsFinal = [];
          break;
        case "Rendimientos":
          this.itemsInicial = [
            {
              id: "email",
              title: "Email del productor",
              subtitle: "Email del productor al que asociar la operación"
            },
            {
              id: "codigo",
              title: "Código del productor",
              subtitle: "Código del productor al que asociar la operación"
            },
            {
              id: "fecha",
              title: "Fecha",
              subtitle: "Fecha del pesaje"
            },
            {
              id: "albaran",
              title: "Nº Albarán",
              subtitle: "Albarán del pesaje"
            },
            {
              id: "kilos",
              title: "Kilos",
              subtitle: "Nº de kilos pesados"
            },
            {
              id: "rendimiento",
              title: "Rendimiento",
              subtitle: "Rendimiento del pesaje"
            },
            {
              id: "acidez",
              title: "Acidez",
              subtitle: "Acidez del producto"
            },
            {
              id: "aceite",
              title: "Aceite",
              subtitle: "Aceite resultante"
            },
            {
              id: "calidad",
              title: "Calidad aceite",
              subtitle: "Calidad en caso de aceite"
            },
            {
              id: "categoría",
              title: "Categoría",
              subtitle: "Categoría del frutal"
            },
            {
              id: "humedad",
              title: "Humedad",
              subtitle: "Humedad en caso de cáscara o frutal"
            },
            {
              id: "pipa",
              title: "Pipa",
              subtitle: "Producto resultante en caso de cáscara o frutal"
            },
            {
              id: "descuento",
              title: "Descuento",
              subtitle: "Descuento aplicable al pesaje"
            },
          ];
          this.itemsFinal = [];
          break;
        default:
          this.itemsInicial = [];
          this.itemsFinal = [];
          break;
      }
    },
    async start(){
      if(this.itemsFinal.length != 0 && this.datos != null && this.separador != ""){
        let read = new FileReader();
        read.readAsBinaryString(this.datos);
        this.reading = true;
        read.onload = () => {
          basics.csvToObject(read.result, this.itemsFinal.map((e) => e.id), this.separador).then(async result => {       
            this.datosImportados = result;
          });
        }
        read.onerror = () => {
          this.$refs.snackbar.setNew(true, "El documento de carga NO se ha subido correctamente. Asegúrese de que el fichero tiene una estructura correcta.", "Cerrar", "red");
        }
      }else{
        this.$refs.snackbar.setNew(true, "ERROR. Debes subir un fichero y seleccionar en que orden están las columnas en el mismo antes de empezar con la importación.", "Cerrar", "red");
      }
    },
    deleteItem(item){
      this.datosImportados = this.datosImportados.filter(function(ele){ return ele != item; });
    },
    async acceptData(){
      this.registrosTotal = this.datosImportados.length;
      let data, response;
      let correctos = 0; let incorrectos = 0; let result = "";
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+this.$store.state.auth
      };
      this.datosImportados = JSON.parse(JSON.stringify(this.datosImportados));
      switch (this.tipo) {
        case "Productores":
          for (let i = 0; i < this.datosImportados.length; i++) {
            this.registrosLeidos = i+1;
            data = {
              nombre: this.datosImportados[i]["nombre"]+", "+this.datosImportados[i]["apellidos"],
              NIF: this.datosImportados[i]["cif"],
              codigo: this.datosImportados[i]["codigo"],
              email: this.datosImportados[i]["email"],
              telefono: this.datosImportados[i]["telefono"],
              direccion: this.datosImportados[i]["direccion"],
              localidad: this.datosImportados[i]["localidad"],
              provincia: this.datosImportados[i]["provincia"],
              cp: this.datosImportados[i]["cp"],
              password: this.datosImportados[i]["contrasena"],
              fechaExpiracion: "2040-12-31",
            };
            response = await fetch(
              `${BASE_URL}/productor`,
              {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data),
              }
            );
            switch (response.status) {
              case 200:
                correctos++;
                break;
              default:
                result += "Linea "+this.registrosLeidos+": El productor no se ha podido crear. Recuerda que los campos 'Nombre', 'Email' y 'Contraseña' son obligatorios. \n";
                incorrectos++;
                break;
            }
          }
          await this.$store.dispatch('getProductor');
          break;
        case "Fincas":
          for (let i = 0; i < this.datosImportados.length; i++) {
            this.registrosLeidos = i+1;
            let email = this.datosImportados[i]["email"];
            let codigo = this.datosImportados[i]["codigo"];
            if(email!="" || codigo!=""){
              let responseProd;
              if(email!=""){
                responseProd = await genericReload(this.$store.state.auth, `productor/?email=${email}`);
              }else{
                responseProd = await genericReload(this.$store.state.auth, `productor/?codigo=${codigo}`);
              }
              if (responseProd.status === 200) {
                const json = await responseProd.json();
                const id = json.body[0].id;
                data = {
                  idProductor: id,
                  identificadorFinca: this.datosImportados[i]["identificador"],
                  nombre: this.datosImportados[i]["nombre"],
                  superficie: this.datosImportados[i]["superficie"],
                  medidaSuperficie: "HA",
                  plantas: this.datosImportados[i]["plantas"],
                  tipoPlanta: this.datosImportados[i]["variedad"],
                  poligono: this.datosImportados[i]["poligono"],
                  parcela: this.datosImportados[i]["parcela"],
                  recinto: this.datosImportados[i]["recinto"],
                  localidad: this.datosImportados[i]["localidad"],
                  provincia: this.datosImportados[i]["provincia"],
                };
                response = await fetch(
                  `${BASE_URL}/finca`,
                  {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                  }
                );
                switch (response.status) {
                  case 200:
                    correctos++;
                    break;
                  default:
                    result += "Linea "+this.registrosLeidos+": La finca no se ha podido crear. Recuerda que los campos 'Email', 'Nombre' e 'Identificador de la finca' son obligatorios. \n";
                    incorrectos++;
                    break;
                }
              }else{
                result += "Linea "+this.registrosLeidos+": La finca no se ha importado porque el email dado no pertenece a ningún productor actual. \n";
                incorrectos++;
              }
            }else{
              result += "Linea "+this.registrosLeidos+": La finca no se ha importado porque el campo email está vacío. \n";
              incorrectos++;
            }
          }
          break;
        case "Entradas de producto":
          for (let i = 0; i < this.datosImportados.length; i++) {
            this.registrosLeidos = i+1;
            let email = this.datosImportados[i]["email"];
            let codigo = this.datosImportados[i]["codigo"];
            let identificador = this.datosImportados[i]["finca"];
            if((email!="" || codigo!="") && identificador!=""){
              let responseProd;
              if(email!=""){
                responseProd = await genericReload(this.$store.state.auth, `productor/?email=${email}`);
              }else{
                responseProd = await genericReload(this.$store.state.auth, `productor/?codigo=${codigo}`);
              }
              if (responseProd.status === 200) {
                const json = await responseProd.json();
                const id = json.body[0].id;
                const responseFinca = await genericReload(this.$store.state.auth, `finca/?identificador=${identificador}`);
                if (responseFinca.status === 200) {
                  const jsonFinca = await responseFinca.json();
                  const idFinca = jsonFinca.body[0].id;
                  data = {
                    idProductor: id,
                    identificadorFinca: idFinca,
                    tipo: this.datosImportados[i]["producto"],
                    fecha: this.datosImportados[i]["fecha"],
                    kilos: this.datosImportados[i]["kilos"],
                    arrendado: this.datosImportados[i]["arrendado"],
                    comentario: this.datosImportados[i]["comentario"],
                    coop: 1,
                    rendimiento: this.datosImportados[i]["rendimiento"],
                    rendimiento2: this.datosImportados[i]["rendimiento"],
                    acidez: this.datosImportados[i]["acidez"],
                    aceite: this.datosImportados[i]["aceite"],
                    calidad: this.datosImportados[i]["calidad"],
                    humedad: this.datosImportados[i]["humedad"],
                    pipa: this.datosImportados[i]["pipa"],
                    albaran: this.datosImportados[i]["albaran"],
                    categoria: this.datosImportados[i]["categoria"],
                    descuento: this.datosImportados[i]["descuento"],
                  };
                  response = await fetch(
                    `${BASE_URL}/entrada`,
                    {
                      method: 'POST',
                      headers: headers,
                      body: JSON.stringify(data),
                    }
                  );
                  switch (response.status) {
                    case 200:
                      correctos++;
                      break;
                    default:
                      result += "Linea "+this.registrosLeidos+": La entrada no se ha podido crear. Recuerda que los campos 'Email', 'Producto', 'Identificador de la finca', 'Fecha', 'Kilos', y 'Comentario' son obligatorios. \n";
                      incorrectos++;
                      break;
                  }
                }else{
                  result += "Linea "+this.registrosLeidos+": La entrada no se ha importado porque el identificador dado no pertenece a ninguna finca existente. \n";
                  incorrectos++;
                }
              }else{
                result += "Linea "+this.registrosLeidos+": La entrada no se ha importado porque el email dado no pertenece a ningún productor actual. \n";
                incorrectos++;
              }
            }else{
              result += "Linea "+this.registrosLeidos+": La finca no se ha importado porque el campo email o el campo identificador de la finca está vacío. \n";
              incorrectos++;
            }
          }
          break;
        case "Liquidaciones y anticipos":
          for (let i = 0; i < this.datosImportados.length; i++) {
            this.registrosLeidos = i+1;
            let email = this.datosImportados[i]["email"];
            let codigo = this.datosImportados[i]["codigo"];
            if((email!="" || codigo!="") && identificador!=""){
              let responseProd;
              if(email!=""){
                responseProd = await genericReload(this.$store.state.auth, `productor/?email=${email}`);
              }else{
                responseProd = await genericReload(this.$store.state.auth, `productor/?codigo=${codigo}`);
              }
              if (responseProd.status === 200) {
                const json = await responseProd.json();
                const id = json.body[0].id;
                data = {
                  idProductor: id,
                  fecha: this.datosImportados[i]["fecha"],
                  kilos: this.datosImportados[i]["kilos"],
                  precioKilo: this.datosImportados[i]["precioKilo"],
                  tipo: this.datosImportados[i]["tipo"],
                  mostrar: (this.datosImportados[i]["tipo"] == "K") ? 0 : 1,
                  calidad: this.datosImportados[i]["rencalidaddimiento"],
                  producto: this.datosImportados[i]["producto"],
                  coop: 1,
                  escandallo: this.datosImportados[i]["escandallo"],
                  tipoescandallo: this.datosImportados[i]["tipoescandallo"],
                  iva: this.datosImportados[i]["iva"],
                  irpf: this.datosImportados[i]["irpf"],
                  estado: 'aceptado',
                };
                response = await fetch(
                  `${BASE_URL}/liquidacion`,
                  {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(data),
                  }
                );
                switch (response.status) {
                  case 200:
                    correctos++;
                    break;
                  default:
                    result += "Linea "+this.registrosLeidos+": La entrada no se ha podido crear. Recuerda que los campos 'Email', 'Producto', 'Identificador de la finca', 'Fecha', 'Kilos', y 'Comentario' son obligatorios. \n";
                    incorrectos++;
                    break;
                }
              }else{
                result += "Linea "+this.registrosLeidos+": La entrada no se ha importado porque el email dado no pertenece a ningún productor actual. \n";
                incorrectos++;
              }
            }else{
              result += "Linea "+this.registrosLeidos+": La finca no se ha importado porque el campo email o el campo identificador de la finca está vacío. \n";
              incorrectos++;
            }
          }
          break;
        case "Rendimientos":
          for (let i = 0; i < this.datosImportados.length; i++) {
            this.registrosLeidos = i+1;
            let email = this.datosImportados[i]["email"];
            let codigo = this.datosImportados[i]["codigo"];
            let identificador = this.datosImportados[i]["albaran"];
            if((email!="" || codigo!="") && identificador!=""){
              let responseProd;
              if(email!=""){
                responseProd = await genericReload(this.$store.state.auth, `productor/?email=${email}`);
              }else{
                responseProd = await genericReload(this.$store.state.auth, `productor/?codigo=${codigo}`);
              }
              if (responseProd.status === 200) {
                const json = await responseProd.json();
                const id = json.body[0].id;
                const responseAlbaran = await genericReload(this.$store.state.auth, `entrada/?idProductor=${id}&albaran=${identificador}`);
                if (responseAlbaran.status === 200) {
                  const jsonEntrada = await responseAlbaran.json();
                  const idEntrada = jsonEntrada.body[0].id;
                  data = {
                    idProductor: id,
                    fecha: this.datosImportados[i]["fecha"],
                    kilos: this.datosImportados[i]["kilos"],
                    rendimiento: this.datosImportados[i]["rendimiento"],
                    rendimiento2: this.datosImportados[i]["rendimiento"],
                    acidez: this.datosImportados[i]["acidez"],
                    aceite: this.datosImportados[i]["aceite"],
                    calidad: this.datosImportados[i]["calidad"],
                    humedad: this.datosImportados[i]["humedad"],
                    pipa: this.datosImportados[i]["pipa"],
                    categoria: this.datosImportados[i]["categoria"],
                    descuento: this.datosImportados[i]["descuento"],
                  };
                  response = await fetch(
                    `${BASE_URL}/entrada/${idEntrada}`,
                    {
                      method: 'POST',
                      headers: headers,
                      body: JSON.stringify(data),
                    }
                  );
                  switch (response.status) {
                    case 200:
                      correctos++;
                      break;
                    default:
                      result += "Linea "+this.registrosLeidos+": La entrada no se ha podido modificar. Recuerda que los campos 'Email', 'Producto' y 'Albarán' son obligatorios. \n";
                      incorrectos++;
                      break;
                  }
                }else{
                  result += "Linea "+this.registrosLeidos+": La entrada no se ha importado porque el albarán dado no existe en el sistema. \n";
                  incorrectos++;
                }
              }else{
                result += "Linea "+this.registrosLeidos+": La entrada no se ha importado porque el email dado no pertenece a ningún productor actual. \n";
                incorrectos++;
              }
            }else{
              result += "Linea "+this.registrosLeidos+": La finca no se ha importado porque el campo email o el campo identificador de la finca está vacío. \n";
              incorrectos++;
            }
          }
          break;
      }
      if(result.length > 0){
        result = "Importaciones correctas: "+correctos+" | Importaciones erróneas: "+incorrectos+"\n"+result;
        this.$refs.dialog.setNew("Resultado de importación", result, "preFormatted").then(async(confirm) => {});
      }else{
        this.$refs.dialog.setNew("Resultado de importación", "Todos los datos se han importado correctamente.", "preFormatted").then(async(confirm) => {});
      }
    },
    clearData(){
      this.datosImportados = [];
    }
  }
}
</script>
<style scoped>
.v-input{
  margin-right: 1em;
}
.btnCustom{
  margin-right: 1em;
}
</style>